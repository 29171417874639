import useTheme from '@theme'
import styles from './homePage.module.css'

import Section from '@html/Section'
import P from '@html/P'
import A from '@html/A'
import Div from '@html/Div'
import Span from '@html/Span'
import H1 from '@html/H1'
import HR from '@html/HR'

import GradientText from '@components/text/aesthetic/GradientText'
import ContactButton from '@components/button/header/ContactButton'
import WaveTransition from '@components/transition/WaveTransition'
import SplitView from '@components/layout/view/SplitView'
import MockupImage from '@components/image/mockup/MockupImage'
import PosImage from '@components/image/pos/PosImage'
import ContactForm from '@components/form'
import SubmitButton from '@components/button/submit/SubmitButton'
import AiWidget from '@components/widget/ai/aiWidget'
import Footer from '@components/global/footer'

import HomePageHeader from './header'
import Marquee from 'react-fast-marquee'

import UnderConstructionImage from '@images/placeholder/under_construction_freepik.svg'
import CSharpLogo from '@images/stack/csharp.svg'
import HTML5Logo from '@images/stack/HTML5.svg'
import CSS3Logo from '@images/stack/CSS3.svg'
import NETCore from '@images/stack/NETCore.svg'

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import FinishIcon from '@images/icon/finish.svg'
import AddressBookIcon from '@images/icon/addressBook.svg'
import LightBulbIcon from '@images/icon/lightBulb.svg'
import CodeIcon from '@images/icon/code.svg'
import DraftIcon from '@images/icon/draft.svg'

import { useState } from 'react'
import { Link } from 'react-router-dom'

import { motion } from 'framer-motion'
import PageAnimation from '../../animtation/pageAnimation'
import PageTitle from '@src/components/page/pageTitle'



export default function HomePage() {
  const theme = useTheme()

  const [openWarning, setOpenWarning] = useState(!(window.localStorage.getItem("warningDismissed")))

  return (
    <>
        <PageTitle pageName='Home' />

        { openWarning &&
            <div style={{position: 'fixed', height: '100lvh', width: '100vw', top: '0', right: '0', bottom: '0', background: 'var(--background-color)', zIndex: '100', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'var(--foreground-color)', textAlign: 'center'}}>
                <img style={{height: '10em'}} src={UnderConstructionImage} alt='under construction'/>
                
                <h1 style={{padding: '0 3em'}}>Under Construction</h1>
                <p style={{padding: '0 3em', margin: '0'}}>This site is currently a work in progress. Check back soon to see what's changed!</p>
                <p style={{marginBottom: '2em', padding: '0 3em'}}>In the mean time, feel free to talk to our AI powered chat bot. Try asking "Who are you?" or "Whats is your email address?"</p>
                
                <SubmitButton onClick={() => {setOpenWarning(false);window.localStorage.setItem("warningDismissed", "true")}}>Enter Anyway</SubmitButton>
            </div>
        }

        <AiWidget/>
        
        <motion.div className={`${theme.flexColumn} ${theme.alignCenter} ${theme.relative} ${theme.widthFull}`} animate={PageAnimation.enter} exit={PageAnimation.exit} initial={PageAnimation.initial}>
            <HomePageHeader/>

            {/* Hero */}
            <Div className={`${theme.widthFull} ${theme.flexColumn} ${theme.justifyCenter} ${theme.alignCenter}`} style={{background: 'linear-gradient(var(--background-color), #111)', minHeight: '70svh'}}>
                <Div className={`${theme.widthLarge} ${theme.flexColumn} ${theme.alignJustifyCenter}`} style={{maxWidth: 'calc(min(90vw, 70em))'}}>
                    <Section className={`${theme.widthFull} ${theme.textCenter} ${theme.flexColumn} ${theme.alignJustifyCenter}`}>
                        <P className={`${theme.paddingMarginNone} ${theme.fontHeavy}`}>Impress with IntraDesign</P> 
                        <GradientText style={{fontSize: 'calc(min(10vw, 5em))'}}  className={`${theme.paddingMarginNone} ${theme.font1XHeavy}`}>
                            Impress your customers with a website that <Span style={{WebkitTextFillColor: 'var(--primary-color-bright)', textDecoration: 'underline solid var(--primary-color-bright)'}}>inspires</Span>
                        </GradientText>
                    </Section>
                </Div>
            </Div>

            <WaveTransition />

            <Div className={`${theme.widthFull} ${theme.flexColumn} ${theme.justifyCenter} ${theme.alignCenter}`} style={{background: 'var(--background-color-secondary)'}}>
                <Div className={`${theme.widthLarge} ${theme.flexColumn} ${theme.alignJustifyCenter}`} style={{maxWidth: '90vw'}}>
                    <SplitView>
                        <MockupImage/>

                        <Div className={`${theme.flexColumn}`} style={{flex: '1', maxWidth: '30em', flexBasis: '20em'}}>
                            <H1 className={theme.font3XLarge}>Responsive design, built in.</H1>
                            
                            <HR/>
                            
                            <P><b>According to <A href='https://gs.statcounter.com/platform-market-share/desktop-mobile-tablet'>this</A> article, over 55% of website visits come from mobile devices. Why should your website have to leave these customers out?</b></P>
                            <P>All of our projects come with built in responsive design so your website looks great on any device. Throughout the development cycle of your project, we continuously test, modify and retest our code to ensure every customer has a fantastic experience with your business.</P>
                            
                            <Div style={{height: '1em'}}/>

                            <Link to='/contact'>
                                <ContactButton className={theme.fontLarge} style={{width: '10em'}}/>
                            </Link>
                        </Div>
                    </SplitView>

                    <SplitView reverse>
                        <Div className={`${theme.flexColumn}`} style={{flex: '1', maxWidth: '30em', flexBasis: '20em'}}>
                            <H1 className={`${theme.font3XLarge} ${theme.textWrap}`} style={{maxWidth: '12em'}}>Integrate with your POS and other services</H1>
                            
                            <HR/>
                            
                            <P><b>Our professional development team will build you the integrations you need to optimise every part of your online business.</b></P>
                            <P>Improper integrations with POS / stock management systems causes slowdowns in your workplace and distrust with your customers. Build trust by ensuring what your customers see is what you want them to see.</P>
                            <P>Let us know about your POS and project and we'll be more than happy to discuss your needs as a business.</P>
                            
                            <Div style={{height: '1em'}}/>

                            <Link to='/'>
                                <ContactButton className={theme.fontLarge} style={{width: '10em'}}/>
                            </Link>
                        </Div>

                        <PosImage/>
                    </SplitView>

                    <Section className={`${theme.widthFull} ${theme.paddingLarge} ${theme.alignJustifyCenter} ${theme.flexColumn}`} style={{marginTop: '0'}}>
                        <H1 className={`${theme.paddingMedium}`}>How it works</H1>

                        <VerticalTimeline className={styles.verticalTimeline}>
                            <VerticalTimelineElement
                                contentStyle={{ background: 'var(--primary-color)', color: '#fff' }}
                                contentArrowStyle={{ borderRight: '7px solid var(--primary-color)' }}
                                date="Contact Us"
                                iconStyle={{ background: 'var(--primary-color)', color: 'var(--primary-color)', display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                                icon={<img src={AddressBookIcon} style={{width: '50%', filter: 'invert()'}} alt='contact'/>}
                            >
                                <h3 className="vertical-timeline-element-title">Get in touch</h3>
                                <p>Contact us via phone or email and we'll get back to you as quickly as possible</p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                contentStyle={{ background: 'var(--secondary-color)', color: '#fff' }}
                                contentArrowStyle={{ borderRight: '7px solid var(--secondary-color)' }}
                                date="Your Idea"
                                iconStyle={{ background: 'var(--primary-color)', color: 'var(--secondary-color)', display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                                icon={<img src={LightBulbIcon} style={{width: '50%', filter: 'invert()'}} alt='idea'/>}
                            >
                                <h3 className="vertical-timeline-element-title">Let us know how we can help</h3>
                                <p>We'll make sure we fully understand what you're looking for</p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                contentStyle={{ background: 'var(--tertiary-color)', color: '#fff' }}
                                contentArrowStyle={{ borderRight: '7px solid var(--tertiary-color)' }}
                                date="Development"
                                iconStyle={{ background: 'var(--primary-color)', color: 'var(--tertiary-color)', display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                                icon={<img src={CodeIcon} style={{width: '50%', filter: 'invert()'}} alt='code'/>}
                            >
                                <h3 className="vertical-timeline-element-title">Development Begins</h3>
                                <p>Our skilled team will get to work realizing your ideas</p>
                                <p>Communication is king. We'll keep you updated the whole time</p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                contentStyle={{ background: 'var(--quaternary-color)', color: '#fff' }}
                                contentArrowStyle={{ borderRight: '7px solid var(--quaternary-color)' }}
                                date="Draft"
                                iconStyle={{ background: 'var(--primary-color)', color: 'var(--quaternary-color)', display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                                icon={<img src={DraftIcon} style={{width: '50%', filter: 'invert()'}} alt='draft'/>}
                            >
                                <h3 className="vertical-timeline-element-title">You'll receive a draft (if applicable)</h3>
                                <p>We want to make sure you're happy with the end result. We'll send you a draft and collect your feedback</p>
                            </VerticalTimelineElement>
                            <VerticalTimelineElement
                                contentStyle={{ background: 'var(--quinary-color)', color: '#fff' }}
                                contentArrowStyle={{ borderRight: '7px solid var(--quinary-color)' }}
                                date="Finish"
                                iconStyle={{background: 'var(--primary-color)', color: 'var(--quinary-color)', display: 'flex', justifyContent: 'center', alignContent: 'center' }}
                                icon={<img src={FinishIcon} style={{width: '50%', filter: 'invert()'}} alt='finish'/>}
                            >
                                <h3 className="vertical-timeline-element-title">Finishing Touches</h3>
                                <p>We'll work to implement your feedback over multiple iterations until you're fully satisfied with your end result. </p>
                            </VerticalTimelineElement>
                        </VerticalTimeline>
                    </Section>
                    
                    <H1>Some of our tools</H1>
                </Div>
                
                <Section className={`${theme.widthFull}`} style={{margin: '0', marginBottom: '10em', background: '#333', padding: '40px 0'}}>
                    <Marquee pauseOnHover className={styles.marquee}>
                        <img src={CSharpLogo} alt='c# logo'/>
                        <img src={HTML5Logo} alt='HTML5 logo'/>
                        <img src={CSS3Logo} alt='CSS3 logo'/>
                        <img src={NETCore} alt='.NET Core logo'/>
                        <a href="https://aws.amazon.com/what-is-cloud-computing"><img src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Powered by AWS Cloud Computing"/></a>
                        <a href="https://jetbrains.com"><img src="https://resources.jetbrains.com/storage/products/company/brand/logos/jb_square.svg" alt="JetBrains Logo (Main) logo."/></a>
                        
                        <img src={CSharpLogo} alt='c# logo'/>
                        <img src={HTML5Logo} alt='HTML5 logo'/>
                        <img src={CSS3Logo} alt='CSS3 logo'/>
                        <img src={NETCore} alt='.NET Core logo'/>
                        <a href="https://aws.amazon.com/what-is-cloud-computing"><img src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Powered by AWS Cloud Computing"/></a>
                        <a href="https://jetbrains.com"><img src="https://resources.jetbrains.com/storage/products/company/brand/logos/jb_square.svg" alt="JetBrains Logo (Main) logo."/></a>
                    </Marquee>
                </Section>

                <ContactForm/>
            </Div>

            <Footer/>
        </motion.div>
    </>
  )
}
