import styles from './SplitView.module.css'
import useTheme from '@theme'

import { ReactNode } from "react"

import Section from '@html/Section'

import DefaultHTMLProps from '@components/default/types/defaultProps'


export default function SplitView(props: SplitViewProps & DefaultHTMLProps) {
    const theme = useTheme()

    return (
        <Section className={`${props.reverse ? theme.wrapReverse : theme.wrap} ${theme.textLeft} ${theme.widthFull} ${theme.textCenter} ${theme.flexRow} ${theme.alignJustifyCenter} ${theme.gap3XLarge} ${styles.container} ${props.className}`} style={props.style}>
            {props.children}
        </Section>
    )
}

type SplitViewProps = {
    children: [ReactNode, ReactNode]
    reverse?: boolean
}
