import styles from './notFound.module.css'
import useTheme from '@theme'

import H1 from '@html/H1'
import H2 from '@html/H2'
import P from '@html/P'

import FullLogo, { FullLogoVarient } from '@components/image/logo/FullLogo'
import { Link } from 'react-router-dom'
import PageTitle from '@src/components/page/pageTitle'


export default function NotFoundPage() {
    const theme = useTheme()

    return (
        <div className={`${styles.container} ${theme.flexColumn} ${theme.alignJustifyCenter} ${theme.widthFull}`}>
            <PageTitle pageName='Not Found' />

            <Link to='/' style={{marginBottom: '50px'}}>
                <FullLogo varient={FullLogoVarient.light} width='15em' />
            </Link>

            <H2>404 - Not Found</H2>

            <H1>Looks like we couldn't find what you're looking for</H1>

            <P>Click <Link to='/'>here</Link> to go back home.</P>
        </div>
    )
}
