import useTheme from '@theme'

import DefaultHTMLProps from "./types/defaultProps";


export default function Ul(props: DefaultHTMLProps) {
    const theme = useTheme()

    return (
        <ul className={`${theme.colorForeground} ${props.className}`} style={{paddingInlineStart: '0', ...props.style}}>
            {props.children}
        </ul>
    )
}
