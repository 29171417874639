import styles from './waveTransition.module.css'

import Div from '@html/Div'


export default function WaveTransition() {
    return (
        <Div className={styles.waves}/>
    )
}
