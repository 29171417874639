import SmallLogoImage from '@images/logo/Small.png'

import DefaultHTMLProps from '@html/types/defaultProps'


export default function SmallLogo(props: {width?: number} & DefaultHTMLProps) {
    const width = props.width ?? 55;

    return (
        <img 
            style={{...props.style, width: `${width}px`}} 
            className={`${props.className}`} 
            src={SmallLogoImage} 
            alt="brand logo" />
    )
}
