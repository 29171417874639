import DefaultHTMLProps from "./types/defaultProps";


export default function A(props: DefaultHTMLProps & {href?: string}) {
    return (
        <a className={props.className} style={{color: 'var(--foreground-color)', ...props.style}} href={props.href}>
            {props.children}
        </a>
    )
}
