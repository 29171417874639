import { MouseEventHandler } from "react";

import DefaultHTMLProps from "./types/defaultProps";


export default function Button(props: DefaultHTMLProps & {onClick?: MouseEventHandler<HTMLButtonElement> | undefined}) {
    return (
        <button className={props.className} style={props.style} onClick={props.onClick}>
            {props.children}
        </button>
    )
}
