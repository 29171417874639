
const PageAnimation = {
    enter: {
        opacity: 1
    },
    exit: {
        opacity: 0
    },
    initial: {
        opacity: 0
    }
}

export default PageAnimation
