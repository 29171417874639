import useTheme from "@theme";
import DefaultHTMLProps from "./types/defaultProps";

export default function Header(props: DefaultHTMLProps) {
    const theme = useTheme()

    return (
        <header 
            className={`${theme.widthFull} ${theme.stickyTop} ${props.className}`} 
            style={{zIndex: '5', ...props.style}}>

            {props.children}
        </header>
    )
}
