import styles from './styles.module.css'

import { createContext, useContext } from 'react'


const Theme = createContext(styles)

export default function useTheme() {
    return useContext(Theme)
}
