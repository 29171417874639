import styles from './posImage.module.css'
import useTheme from '@theme'

import PosTap from '@images/placeholder/credit-card-tap.jpg'


export default function PosImage() {
    const theme = useTheme()

    return (
        <img className={`${styles.container} ${theme.floatDelay}`} src={PosTap} alt='pos showcase'/>
    )
}
