import FullLogoLight from '@images/logo/Full Light.png'
import FullLogoDark from '@images/logo/Full Dark.png'

import DefaultHTMLProps from '@html/types/defaultProps'


export default function FullLogo(props: {varient: FullLogoVarient, width?: string} & DefaultHTMLProps) {
    const width = props.width ?? '200px';

    return (
        <img 
            style={{...props.style, width: width}} 
            className={`${props.className}`} 
            src={getLogo(props.varient)} 
            alt="brand logo" />
    )
}


export enum FullLogoVarient { light, dark }
function getLogo(varient: FullLogoVarient) {
    switch (varient) {
        case FullLogoVarient.dark:
            return FullLogoDark

        default:
        case FullLogoVarient.light:
            return FullLogoLight
    }
}
