import styles from './profilePicture.module.css'

import CoryCoverImage from '@images/people/cory.jpg'
import EthanCoverImage from '@images/people/nd_miata.jpg'

export default function ProfilePicture(props: {user: ProfileUser}) {
    return (
        <img className={`${styles.container}`} src={pictures[props.user]} alt='team portrait'/>
    )
}

type ProfileUser = 'cory' | 'ethan'

const pictures = {
    cory: CoryCoverImage,
    ethan: EthanCoverImage
}
