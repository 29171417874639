import styles from './roleBadge.module.css'
import useTheme from '@theme'

import Div from '@html/Div'
import P from '@html/P'

import CodeIcon from '@images/icon/badge/code.svg'
import GearsIcon from '@images/icon/badge/gears.svg'
import CloudIcon from '@images/icon/badge/cloud.svg'
import CommunicationIcon from '@images/icon/badge/communication.svg'
import BossIcon from '@images/icon/badge/boss.svg'


export default function RoleBadge(props: {children: string, icon: BadgeIcon}) {
    const theme = useTheme()

    return (
        <Div className={`${theme.flexRow} ${theme.justifyAlignCenter} ${theme.gapSmall} ${styles.container}`}>
            <img src={BadgeIcons[props.icon]} alt={props.icon}/>
            <P>{props.children}</P>
        </Div>
    )
}


const BadgeIcons = {
    code: CodeIcon,
    integration: GearsIcon,
    cloud: CloudIcon,
    communication: CommunicationIcon,
    boss: BossIcon
} as const

type BadgeIcon = keyof typeof BadgeIcons
