import styles from './mockupImage.module.css'
import useTheme from '@theme'

import iPhoneMockup from '@images/mockups/Angle iPhone Mockup.png'


export default function MockupImage(props: {varient?: number}) {
    const theme = useTheme()

    const varient = props.varient ?? 1

    return (
        <img className={`${styles.container} ${theme.float}`} src={getImageFromVarient(varient)} alt='iPhone showcase'/>
    )
}


function getImageFromVarient(varient: number) {
    switch (varient) {
        default:
        case 1:
            return iPhoneMockup

        case 2:
            return iPhoneMockup
    }
}
