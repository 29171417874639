import styles from './contactForm.module.css'
import useTheme from '@theme'

import Div from '@html/Div'
import H1 from '@html/H1'
import P from '@html/P'
import A from '@html/A'

import PhoneIcon from '@images/icon/phone.svg'
import EmailIcon from '@images/icon/email.svg'

import SplitView from '@components/layout/view/SplitView'

import { useRef } from 'react'
import SubmitButton from '../button/submit/SubmitButton'
import FullLogo, { FullLogoVarient } from '../image/logo/FullLogo'


export default function ContactForm() {
    const theme = useTheme()

    const formRef = useRef(null as null | HTMLFormElement)

    const onSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
        formRef.current?.reset()
        
        e.preventDefault()
    }

    return (
        <Div className={`${theme.widthFull} ${theme.flexColumn} ${theme.alignJustifyCenter} ${theme.paddingMedium}`}>
            <SplitView className={styles.container}>
                <Div className={`${styles.textAside} ${theme.paddingLarge}`}>
                    <FullLogo varient={FullLogoVarient.light} width='20em' />

                    <H1>Get in touch</H1>
                    <P>Contact us and we'll get back to you right away.</P>
                    
                    <Div className={`${theme.flexColumn} ${theme.gapSmall}`}>
                        <P className={`${theme.marginPaddingNone} ${theme.fontSmall}`} style={{userSelect: 'none', marginBottom: '0.5em'}}>Our Details</P>
                        <Div className={`${theme.flexRow} ${theme.gapSmall}`}>
                            <img className={theme.colorPrimarySvg} src={PhoneIcon} alt='phone'/>
                            <A href='tel:+61410504373'>0410504373</A>
                        </Div>
                        <Div className={`${theme.flexRow} ${theme.gapSmall}`}>
                            <img className={theme.colorPrimarySvg} src={EmailIcon} alt='email'/>
                            <A href='mailto:admin@intradesign.com.au'>admin@intradesign.com.au</A>
                        </Div>
                    </Div>
                </Div>
                <Div className={`${theme.widthFull} ${theme.paddingLarge}`} style={{maxWidth: '30em', flexBasis: '25em', flex: '1'}}>
                    <form ref={formRef} className={`${theme.flexColumn} ${styles.form}`} onSubmit={onSubmit}>
                        <Div className={`${theme.flexRow} ${theme.widthFull} ${theme.wrap}`}>
                            <input style={{flexBasis: '10em'}} required type='text' placeholder='Name *'/>
                            <input style={{flexBasis: '10em'}} type='text' placeholder='Company'/>
                        </Div>

                        <input required type='text' placeholder='Phone *'/>
                        <input required type='text' placeholder='Email *'/>
                        
                        <textarea placeholder='What would you like to tell us?'/>
                        
                        <SubmitButton className={`${styles.submitButton}`}>Submit</SubmitButton>
                    </form>
                </Div>
            </SplitView>
        </Div>
    )
}
