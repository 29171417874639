import styles from './gradientText.module.css'

import DefaultHTMLProps from '../../default/types/defaultProps'


export default function GradientText(props: DefaultHTMLProps) {
    return (
        <h1 className={`${styles.text} ${props.className}`} style={props.style}>
            {props.children}
        </h1>
    )
}
