import useTheme from '@theme'

import DefaultHTMLProps from "./types/defaultProps";


export default function H1(props: DefaultHTMLProps) {
    const theme = useTheme()

    return (
        <h1 className={`${theme.colorForeground} ${props.className}`} style={props.style}>
            {props.children}
        </h1>
    )
}
