import useTheme from '@theme'

import P from '@html/P'
import H1 from '@html/H1'
import HR from '@html/HR'
import Div from '@html/Div'
import Section from '@html/Section'

import Footer from '@components/global/footer'

import RoleBadge from '@components/badge/roleBadge'
import SplitView from '@components/layout/view/SplitView'
import ProfilePicture from '@components/image/pfp/profilePicture'
import GradientText from '@components/text/aesthetic/GradientText'
import WaveTransition from '@components/transition/WaveTransition'

import { motion } from 'framer-motion'
import PageAnimation from '../../animtation/pageAnimation'
import HomePageHeader from '../home/header'
import PageTitle from '@src/components/page/pageTitle'


export default function AboutUsPage() {
  const theme = useTheme()

  return (
    <motion.div className={`${theme.flexColumn} ${theme.alignCenter} ${theme.relative} ${theme.widthFull}`} animate={PageAnimation.enter} exit={PageAnimation.exit} initial={PageAnimation.initial}>
        <PageTitle pageName='About Us' />

        <HomePageHeader/>

        {/* Hero */}
        <Div className={`${theme.widthFull} ${theme.flexColumn} ${theme.justifyCenter} ${theme.alignCenter}`} style={{background: 'linear-gradient(var(--background-color), #111)', minHeight: '70svh'}}>
            <Div className={`${theme.widthLarge} ${theme.flexColumn} ${theme.alignJustifyCenter}`} style={{maxWidth: 'calc(min(90vw, 70em))'}}>
                <Section className={`${theme.widthFull} ${theme.textCenter} ${theme.flexColumn} ${theme.alignJustifyCenter}`}>
                    <P className={`${theme.paddingMarginNone} ${theme.fontHeavy}`}>Connect with IntraDesign</P> 
                    <GradientText style={{fontSize: 'calc(min(10vw, 5em))'}}  className={`${theme.paddingMarginNone} ${theme.font1XHeavy}`}>
                        Who is IntraDesign?
                    </GradientText>
                </Section>
            </Div>
        </Div>

        <WaveTransition />

        <Div className={`${theme.widthFull} ${theme.flexColumn} ${theme.justifyCenter} ${theme.alignCenter}`} style={{background: 'var(--background-color-secondary)'}}>
            <Div className={`${theme.widthLarge} ${theme.flexColumn} ${theme.alignJustifyCenter}`} style={{maxWidth: '90vw'}}>

                
                <Section style={{marginBottom: '0', marginTop: '20em'}}>
                    <GradientText style={{fontSize: 'calc(min(9vw, 4em))'}}  className={`${theme.paddingMarginNone} ${theme.font1XHeavy}`}>
                        You matter to us
                    </GradientText>
                </Section>

                <Section style={{marginBottom: '15em', marginTop: '1em'}}>
                    <P style={{maxWidth: '50em'}}>Here at IntraDesign Software, we believe in doing business the old fashioned way. We care about your needs and experience with us and are open to communication. The staff at IntraDesign belive that customers come first, and will place you at the forefront of your experience with us.</P>
                </Section>

                <Section style={{marginBottom: '0'}}>
                    <GradientText style={{fontSize: 'calc(min(9vw, 4em))'}}  className={`${theme.paddingMarginNone} ${theme.font1XHeavy}`}>
                        Meet the team
                    </GradientText>
                </Section>

                <SplitView style={{marginBottom: '0'}}>
                    <ProfilePicture user='cory'/>

                    <Div className={`${theme.flexColumn}`} style={{flex: '1', maxWidth: '30em', flexBasis: '20em'}}>
                        <Div className={`${theme.flexColumn} ${theme.justifyCenter} ${theme.alignStart} ${theme.gapSmall}`}>
                            <H1 className={`${theme.font3XLarge} ${theme.marginPaddingNone}`}>Cory Irwin</H1>
                            <Div className={`${theme.flexRow} ${theme.gapSmall} ${theme.wrap}`}>
                                <RoleBadge icon='boss'>Proprietor</RoleBadge>
                                <RoleBadge icon='communication'>Communications Expert</RoleBadge>
                            </Div>
                        </Div>
                        
                        <HR/>
                        
                        <P>Cory is the founder front of IntraDesign. His understanding of business and software combine to bring you a development experience you won't find anywhere else.</P>
                    </Div>
                </SplitView>

                <SplitView>
                    <ProfilePicture user='ethan'/>

                    <Div className={`${theme.flexColumn}`} style={{flex: '1', maxWidth: '30em', flexBasis: '20em'}}>
                        <Div className={`${theme.flexColumn} ${theme.justifyCenter} ${theme.alignStart} ${theme.gapSmall}`}>
                            <H1 className={`${theme.font3XLarge} ${theme.marginPaddingNone}`}>Ethan Rushbrook</H1>
                            <Div className={`${theme.flexRow} ${theme.gapSmall} ${theme.wrap}`}>
                                <RoleBadge icon='code'>Principle Engineer</RoleBadge>
                                <RoleBadge icon='integration'>Integrations Expert</RoleBadge>
                                <RoleBadge icon='cloud'>Cloud Expert</RoleBadge>
                                <RoleBadge icon='code'>Web Developer</RoleBadge>
                                <RoleBadge icon='code'>Back End Developer</RoleBadge>
                            </Div>
                        </Div>
                        
                        <HR/>
                        
                        <P>Ethan is a hard working, skilled software engineer with years of experience. Through thoughtful work, he brings your ideas to life.</P>
                    </Div>
                </SplitView>
            </Div>
        </Div>

        <Footer/>
    </motion.div>
  )
}
