import useTheme from '@theme'

import DefaultHTMLProps from "./types/defaultProps";


export default function Li(props: DefaultHTMLProps) {
    const theme = useTheme()

    return (
        <li className={`${theme.colorForeground} ${props.className}`} style={{padding: '0', listStyle: 'none', ...props.style}}>
            {props.children}
        </li>
    )
}
