import styles from './homePage.module.css'
import headerStyles from './header.module.css'
import useTheme from '@theme'

import { useState } from 'react'

import Header from '@html/Header'
import Ul from '@html/Ul'
import Nav from '@html/Nav'
import Div from '@html/Div'
import Button from '@html/Button'

import SmallLogo from '@components/image/logo/SmallLogo'
import HeaderLink from '@components/link/header/HeaderLink'
import ContactButton from '@components/button/header/ContactButton'

import CloseIcon from '@images/icon/cross.svg'
import BarsIcon from '@images/icon/bars.svg'

import useWindowDimensions from '@src/hooks/window/useWindowDimensions'
import { Link } from 'react-router-dom'


export default function HomePageHeader() {
  const theme = useTheme()

  const dimensions = useWindowDimensions()
  const [userOpen, setOpen] = useState(false)

  const mobileMode = (dimensions.width < 800)
  const absoluteOpen = mobileMode && userOpen

  return (
    <Header 
        className={`${!mobileMode ? styles.header : undefined} ${theme.paddingMedium} ${theme.flexRow} ${theme.alignCenter} ${(!mobileMode || !userOpen) ? theme.justifySpaceBetween : undefined}`}
        style={{background: 'var(--background-color)'}}>


        {(!mobileMode) &&
          <Link to='/' className={(absoluteOpen ? headerStyles.hidden : undefined)}>
            <SmallLogo/>
          </Link>
        }

        <Nav className={(absoluteOpen ? headerStyles.nav : undefined)}>
          {(mobileMode && !userOpen) &&
              <Button className={headerStyles.hamburgerMenuIconClosed} onClick={() => setOpen(x => !x)}>
              <img src={BarsIcon} alt='open hamburger menu' />
            </Button>
          }

          <Ul className={`${theme.flexRow} ${theme.alignJustifyCenter} ${theme.gapLarge} ${((!userOpen && mobileMode) ? headerStyles.hidden : undefined)} ${headerStyles.ul}`}>
            {(mobileMode && userOpen) &&
              <Button className={headerStyles.hamburgerMenuIcon} onClick={() => setOpen(x => !x)}>
                <img src={CloseIcon} alt='close hamburger menu' />
              </Button>
            }

            <Link to='/' className={headerStyles.logo}>
              <SmallLogo width={100}/>
            </Link>

            <HeaderLink href='/'>Home</HeaderLink>
            <HeaderLink href='/why-intradesign'>Why IntraDesign?</HeaderLink>
            <HeaderLink href='/services'>Our Services</HeaderLink>
            <HeaderLink href='/contact'>Contact</HeaderLink>
            <HeaderLink href='/about'>About</HeaderLink>
            <HeaderLink href='/privacy'>Privacy</HeaderLink>

            <Div className={headerStyles.counterWeight}/>
          </Ul>
        </Nav>

        <Link to='/' className={(absoluteOpen ? headerStyles.hidden : undefined)}>
            <ContactButton/>
        </Link>
    </Header>
  )
}
