import PageRouter from "./pages/routing"


// TODO Routing
function App() {
  return (
    <PageRouter/>
  )
}

export default App
