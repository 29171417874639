import useTheme from '@theme'

import DefaultHTMLProps from "./types/defaultProps";


export default function Nav(props: DefaultHTMLProps) {
    const theme = useTheme()

    return (
        <nav className={`${theme.colorForeground} ${props.className}`} style={props.style}>
            {props.children}
        </nav>
    )
}
