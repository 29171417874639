import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom"

import HomePage from "./home"

import NotFoundPage from "./notFound"
import ContactPage from "./contact"
import { AnimatePresence } from "framer-motion"
import { ReactNode } from "react"
import AboutUsPage from "./aboutUs"
import PrivacyPage from "./privacy"


function LocationProvider({ children }: { children: ReactNode | ReactNode[] }) {
    return <AnimatePresence>{children}</AnimatePresence>
}
function GetRoutes() {
    const location = useLocation()
  
    return (
      <Routes location={location} key={location.key}>
        <Route path="/" element={<HomePage/>} errorElement={<NotFoundPage/>} />
        
        <Route path="contact" element={<ContactPage/>} />
        <Route path="about" element={<AboutUsPage/>} />

        <Route path="privacy" element={<PrivacyPage/>} />

        <Route path="*" element={<NotFoundPage/>} errorElement={<NotFoundPage/>} />
      </Routes>
    )
}

export default function PageRouter() {
    return (
        <BrowserRouter>
            <LocationProvider>
                <GetRoutes />
            </LocationProvider>
        </BrowserRouter>
    )
}
