import DefaultHTMLProps from "./types/defaultProps"


export default function Footer(props: DefaultHTMLProps) {
    return (
        <footer className={props.className} style={props.style}>
            {props.children}
        </footer>
    )
}
