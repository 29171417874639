import useTheme from '@theme'

import Footer from '@html/Footer'
import Div from '@html/Div'
import P from '@html/P'
import A from '@html/A'

import FullLogo, { FullLogoVarient } from '@components/image/logo/FullLogo'


export default function PageFooter() {
    const theme = useTheme()
    
    return (
        <Footer className={`${theme.widthFull} ${theme.flexRow} ${theme.justifySpaceBetween} ${theme.paddingLarge}`}>
            <Div className={`${theme.fontSmall} ${theme.flexColumn} ${theme.gapSmall}`}>
                <P style={{userSelect: 'none', color: '#aaa'}} className={theme.marginNone}>Contact</P>
                <P style={{userSelect: 'none'}} className={theme.marginNone}>Cory Irwin</P>
                <A href='mailto:admin@intradesign.com.au'>admin@intradesign.com.au</A>
                <A href='tel:+61410504373'>0410504373</A>
            </Div>

            <Div/>
            
            <Div className={`${theme.fontSmall} ${theme.flexColumn} ${theme.gapSmall} ${theme.alignEnd}`}>
                <FullLogo varient={FullLogoVarient.light} width='8em' />
                <P style={{userSelect: 'none', color: '#aaa'}} className={theme.marginNone}>© 2024</P>
                <P style={{userSelect: 'none', color: '#aaa'}} className={theme.marginNone}>ABN <A href="https://abr.business.gov.au/ABN/View?abn=26856965837">26 856 965 837</A></P>
                <P style={{userSelect: 'none', color: '#aaa'}} className={theme.marginNone}>Website made by us</P>
            </Div>
        </Footer>
    )
}
