
import styles from './submitButton.module.css'
import useTheme from '@theme'

import Button from '@html/Button'


export default function SubmitButton(props: {style?: React.CSSProperties, className?: string, children: string, onClick?: () => void}) {
    const theme = useTheme()

    return (
        <Button 
            className={`${styles.button} ${theme.paddingMedium} ${theme.backgroundPrimary} ${theme.pointer} ${theme.fontMedium} ${theme.colorForeground} ${props.className}`}
            style={props.style}
            onClick={props.onClick}>
            
            {props.children}
        </Button>
    )
}
