
import styles from './contactButton.module.css'
import useTheme from '@theme'

import { Link } from 'react-router-dom'


export default function ContactButton(props: {style?: React.CSSProperties, className?: string}) {
    const theme = useTheme()

    return (
        <Link 
            className={`${styles.button} ${theme.paddingMedium} ${theme.backgroundPrimary} ${theme.pointer} ${theme.fontMedium} ${theme.colorForeground} ${props.className}`}
            style={props.style}
            to="/contact">
            
            Contact Us
        </Link>
    )
}
