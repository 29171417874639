import useTheme from '@theme'

import Section from '@html/Section'
import Div from '@html/Div'
import H1 from '@html/H1'
import HR from '@html/HR'
import P from '@html/P'

import GradientText from '@components/text/aesthetic/GradientText'
import WaveTransition from '@components/transition/WaveTransition'
import SubmitButton from '@components/button/submit/SubmitButton'
import AiWidget from '@components/widget/ai/aiWidget'
import Footer from '@components/global/footer'
import ContactForm from '@components/form'

import UnderConstructionImage from '@images/placeholder/under_construction_freepik.svg'

import { useState } from 'react'

import { motion } from 'framer-motion'
import PageAnimation from '../../animtation/pageAnimation'
import HomePageHeader from '../home/header'
import PageTitle from '@src/components/page/pageTitle'



export default function ContactPage() {
  const theme = useTheme()

  const [openWarning, setOpenWarning] = useState(!(window.localStorage.getItem("warningDismissed")))

  return (
    <>
        <PageTitle pageName='Contact Us' />

        { openWarning &&
            <div style={{position: 'fixed', height: '100lvh', width: '100vw', top: '0', right: '0', bottom: '0', background: 'var(--background-color)', zIndex: '100', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', color: 'var(--foreground-color)', textAlign: 'center'}}>
                <img style={{height: '10em'}} src={UnderConstructionImage} alt='under construction'/>
                
                <h1 style={{padding: '0 3em'}}>Under Construction</h1>
                <p style={{padding: '0 3em', margin: '0'}}>This site is currently a work in progress. Check back soon to see what's changed!</p>
                <p style={{marginBottom: '2em', padding: '0 3em'}}>In the mean time, feel free to talk to our AI powered chat bot. Try asking "Who are you?" or "Whats is your email address?"</p>
                
                <SubmitButton onClick={() => {setOpenWarning(false);window.localStorage.setItem("warningDismissed", "true")}}>Enter Anyway</SubmitButton>
            </div>
        }

        <AiWidget/>
        
        <motion.div className={`${theme.flexColumn} ${theme.alignCenter} ${theme.relative} ${theme.widthFull}`} animate={PageAnimation.enter} exit={PageAnimation.exit} initial={PageAnimation.initial}>
            <HomePageHeader/>

            {/* Hero */}
            <Div className={`${theme.widthFull} ${theme.flexColumn} ${theme.justifyCenter} ${theme.alignCenter}`} style={{background: 'linear-gradient(var(--background-color), #111)', minHeight: '70svh'}}>
                <Div className={`${theme.widthLarge} ${theme.flexColumn} ${theme.alignJustifyCenter}`} style={{maxWidth: 'calc(min(90vw, 70em))'}}>
                    <Section className={`${theme.widthFull} ${theme.textCenter} ${theme.flexColumn} ${theme.alignJustifyCenter}`}>
                        <P>Get in touch with IntraDesign</P>
                        <GradientText style={{fontSize: 'calc(min(10vw, 5em))'}}  className={`${theme.paddingMarginNone} ${theme.font1XHeavy}`}>
                            Our Contact Info
                        </GradientText>
                    </Section>
                </Div>
            </Div>

            <WaveTransition />

            <Div className={`${theme.widthFull} ${theme.flexColumn} ${theme.justifyCenter} ${theme.alignCenter}`} style={{background: 'var(--background-color-secondary)'}}>
                <Div className={`${theme.widthLarge} ${theme.flexColumn} ${theme.alignJustifyCenter}`} style={{maxWidth: '90vw'}}>
                    <Section style={{marginBottom: '0'}}>
                        <Div className={`${theme.flexColumn}`} style={{flex: '1', maxWidth: '30em', flexBasis: '20em'}}>
                            <H1 className={`${theme.font3XLarge} ${theme.textWrap}`} style={{maxWidth: '12em'}}>Get in touch</H1>
                            
                            <HR/>
                            
                            <P>Feel free to give us a call or send us an email at any time and we'll do our best to get back to you as quickly as possible. Not sure what you're after? Let us know what your end goal is and one of our experienced staff members will happily guide you through the process.</P>
                            
                            <Div style={{height: '1em'}}/>
                        </Div>
                    </Section>
                </Div>

                <ContactForm/>
            </Div>

            <Footer/>
        </motion.div>
    </>
  )
}
