import styles from './headerLink.module.css'

import Li from '@html/Li'

import DefaultHTMLProps from "../../default/types/defaultProps"
import { Link } from 'react-router-dom'


export default function HeaderLink(props: {href: string} & DefaultHTMLProps) {
    return (
        <Li><Link className={`${styles.a} 
            ${props.className}`} 
            style={{fontSize: '1.1em', ...props.style}} 
            to={props.href}>

            {props.children}
        </Link></Li>
    )
}
